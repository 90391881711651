import { v4 as uuidv4 } from "uuid"
import { Storage } from "aws-amplify"
import { applyTaxRate } from "./tax"

const DroidSerifUrl = "https://fonts.googleapis.com/css2?family=PT+Serif&display=swap"
const LatoUrl = "https://fonts.googleapis.com/css2?family=Lato&display=swap"
const MontserratUrl = "https://fonts.googleapis.com/css2?family=Montserrat&display=swap"
const PacificoUrl = "https://fonts.googleapis.com/css2?family=Pacifico&display=swap"
const RobotoUrl = "https://fonts.googleapis.com/css2?family=Roboto&display=swap"
const DancingScriptUrl = "https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap"

export const getGoogleFontUrl = family => {
  if (family === "Montserrat") return MontserratUrl
  if (family === "Lato") return LatoUrl
  if (family === "Droid-Serif") return DroidSerifUrl
  if (family === "Dancing Script") return DancingScriptUrl
  if (family === "Pacifico") return PacificoUrl
  if (family === "Roboto") return RobotoUrl
}

export const customFonts = [
  {
    value: "Montserrat",
    label: "Montserrat",
  },
  {
    value: "Lato",
    label: "Lato",
  },
  {
    value: "Droid-Serif",
    label: "Droid-Serif",
  },
  {
    value: "Dancing Script",
    label: "Dancing Script",
  },
  {
    value: "Pacifico",
    label: "Pacifico",
  },
  {
    value: "Roboto",
    label: "Roboto",
  },
]

export const numberOfCards = [
  {
    value: "250",
    label: "250",
  },
  {
    value: "500",
    label: "500",
  },
  {
    value: "1000",
    label: "1000",
  },
  {
    value: "2500",
    label: "2500",
  },
]

export const cardCardCoverDefaults = {
  line1_x: "50%",
  line1_y: "40%",
  line2_x: "50%",
  line2_y: "56%",
  line3_x: "50%",
  line3_y: "70%",
  fontColor: "#FFFFFF",
}

function setDefaults(cardCover) {
  cardCover.line1_x = cardCover.line1_x || cardCardCoverDefaults.line1_x
  cardCover.line1_y = cardCover.line1_y || cardCardCoverDefaults.line1_y
  cardCover.line2_x = cardCover.line2_x || cardCardCoverDefaults.line2_x
  cardCover.line2_y = cardCover.line2_y || cardCardCoverDefaults.line2_y
  cardCover.line3_x = cardCover.line3_x || cardCardCoverDefaults.line3_x
  cardCover.line3_y = cardCover.line3_y || cardCardCoverDefaults.line3_y
  cardCover.fontColor = cardCover.fontColor || cardCardCoverDefaults.fontColor
}

export const solidCardCovers = [
  {
    value: "Solid Baby Blue",
    fontColor: "#000",
  },
  {
    value: "Solid Black",
  },
  {
    value: "Solid Blue",
  },
  {
    value: "Solid Gray",
  },
  {
    value: "Solid Green",
    fontColor: "#000",
  },
  {
    value: "Solid Light Pink",
    fontColor: "#000",
  },
  {
    value: "Solid Orange",
  },
  {
    value: "Solid Pink",
  },
  {
    value: "Solid Purple",
  },
  {
    value: "Solid Turquoise",
  },
  {
    value: "Solid White",
    fontColor: "#000",
  },
  {
    value: "Solid Yellow",
    fontColor: "#000",
  },
]
solidCardCovers.forEach(setDefaults)

export const patternCardCovers = [
  {
    value: "Pattern Gray Blue",
  },
  {
    value: "Pattern Orange",
  },
  {
    value: "Pattern Pink",
  },
  {
    value: "Pattern Purple",
  },
  {
    value: "Pattern Turquoise",
  },
  {
    value: "Pattern Wood Grain",
  },
  {
    value: "Pattern Yellow",
  },
]
patternCardCovers.forEach(setDefaults)

export const holidayCardCovers = [
  {
    value: "Holiday Celebrate 1",
  },
  {
    value: "Holiday Celebrate 2",
  },
  {
    value: "Holiday Christmas 1",
  },
  {
    value: "Holiday Christmas 2",
  },
  {
    value: "Holiday Christmas 3",
  },
  {
    value: "Holiday Christmas 4",
  },
  {
    value: "Holiday Fathers Day 1",
  },
  {
    value: "Holiday Fathers Day 2",
  },
  {
    value: "Holiday Hanukkah",
  },
  {
    value: "Holiday Mothers Day 1",
  },
  {
    value: "Holiday Mothers Day 3",
  },
  {
    value: "Holiday Valentines 1",
  },
  {
    value: "Holiday Valentines 2",
  },
  {
    value: "Holiday Valentines 3",
  },
  {
    value: "Holiday Valentines 4",
  },
  {
    value: "Holiday Valentines 5",
  },
]
holidayCardCovers.forEach(setDefaults)

export const giftGivingCardCovers = [
  {
    value: "themes_birthday_1",
    line1_y: "38%",
    line2_y: "62%",
    line3_y: "85%",
  },
  {
    value: "themes_birthday_2",
    line1_y: "32%",
    line2_y: "44%",
    line3_y: "53%",
    fontColor: "#000000",
  },
  {
    value: "themes_birthday_3",
    line1_y: "48%",
    line2_y: "60%",
    line3_y: "71%",
  },
  {
    value: "themes_birthday_4",
    line1_y: "22%",
    line2_y: "38%",
    line3_y: "52%",
  },
  {
    value: "themes_graduation_1",
  },
  {
    value: "themes_graduation_2",
    line1_y: "55%",
    line2_y: "71%",
    line3_y: "85%",
  },
  {
    value: "themes_graduation_3",
    line1_y: "65%",
    line2_y: "76%",
    line3_y: "85%",
  },
  {
    value: "themes_graduation_4",
    line1_y: "50%",
    line2_y: "66%",
    line3_y: "80%",
  },
  {
    value: "themes_graduation_5",
    line1_y: "67%",
    line2_y: "77%",
    line3_y: "86%",
  },
  {
    value: "themes_wedding_1",
    line1_y: "62%",
    line2_y: "74%",
    line3_y: "85%",
  },
  {
    value: "themes_wedding_2",
    line1_y: "62%",
    line2_y: "74%",
    line3_y: "85%",
    fontColor: "#000",
  },
  {
    value: "themes_wedding_3",
    line1_y: "62%",
    line2_y: "74%",
    line3_y: "85%",
  },
  {
    value: "themes_wedding_4",
  },
]
giftGivingCardCovers.forEach(setDefaults)

export const businessCardCovers = [
  {
    value: "business_barber",
    line1_y: "53%",
    line2_y: "68%",
    line3_y: "80%",
    fontColor: "#D47C7C",
  },
  {
    value: "business_cafe",
    line1_y: "50%",
    line2_y: "65%",
    line3_y: "80%",
  },
  {
    value: "business_coffee",
  },
  {
    value: "business_frozen_yoghurt",
    line1_y: "30%",
    line2_y: "46%",
    line3_y: "60%",
  },
  {
    value: "business_groceries",
  },
  {
    value: "business_jewellery",
    line1_y: "50%",
    line2_y: "66%",
    line3_y: "80%",
  },
  {
    value: "business_military_1",
    line1_y: "50%",
    line2_y: "66%",
    line3_y: "80%",
  },
  {
    value: "business_military_2",
  },
  {
    value: "business_pets",
    line1_y: "55%",
    line2_y: "71%",
    line3_y: "85%",
  },
  {
    value: "business_restaurants",
    line1_y: "43%",
    line3_y: "67%",
    fontColor: "#000",
  },
  {
    value: "business_retail",
  },
  {
    value: "business_salon",
    line1_y: "57%",
    line2_y: "72%",
    line3_y: "85%",
  },
  {
    value: "business_thank_you_1",
    line1_y: "57%",
    line2_y: "72%",
    line3_y: "85%",
  },
  {
    value: "business_thank_you_2",
    line1_y: "56%",
    line2_y: "71%",
    line3_y: "85%",
    fontColor: "#569DCA",
  },
  {
    value: "business_thank_you_3",
    line1_y: "56%",
    line2_y: "71%",
    line3_y: "85%",
  },
]
businessCardCovers.forEach(setDefaults)

export const legacyCardCovers = [
  {
    value: "legacy_1",
    line1_y: "72%",
    line2_y: "82%",
    line3_y: "90%",
    fontColor: "#000",
  },
  {
    value: "legacy_2",
    line1_y: "50%",
    line2_y: "66%",
    line3_y: "80%",
    fontColor: "#000",
  },
  {
    value: "legacy_3",
    line1_y: "50%",
    line2_y: "66%",
    line3_y: "80%",
    fontColor: "#000",
  },
  {
    value: "legacy_4",
    line1_y: "48%",
    line2_y: "61%",
  },
  {
    value: "legacy_5",
    line1_y: "50%",
    line2_y: "66%",
    line3_y: "80%",
    fontColor: "#000",
  },
  {
    value: "legacy_6",
    line1_y: "50%",
    line2_y: "66%",
    line3_y: "80%",
    fontColor: "#000",
  },
  {
    value: "legacy_7",
    line1_y: "55%",
    line2_y: "71%",
    line3_y: "85%",
    fontColor: "#000",
  },
  {
    value: "legacy_8",
    line1_y: "70%",
    line2_y: "80%",
    line3_y: "90%",
    fontColor: "#000",
  },
  {
    value: "legacy_9",
    line1_y: "20%",
    line2_y: "30%",
    line3_y: "40%",
    fontColor: "#000",
  },
  {
    value: "legacy_10",
    line1_y: "55%",
    line2_y: "71%",
    line3_y: "85%",
  },
  {
    value: "legacy_11",
    line1_x: "41%",
    line1_y: "20%",
    line2_x: "41%",
    line2_y: "40%",
    line3_x: "41%",
    line3_y: "53%",
    fontColor: "#000",
  },
  {
    value: "legacy_12",
    line1_x: "41%",
    line1_y: "20%",
    line2_x: "41%",
    line2_y: "40%",
    line3_x: "41%",
    line3_y: "53%",
    fontColor: "#000",
  },
  {
    value: "legacy_13",
    line1_y: "47%",
    line2_y: "66%",
    line3_y: "85%",
    fontColor: "#000",
  },
  {
    value: "legacy_14",
  },
  {
    value: "legacy_15",
    line1_y: "50%",
    line2_y: "67%",
    line3_y: "85%",
    fontColor: "#000",
  },
  {
    value: "legacy_16",
    line1_y: "50%",
    line2_y: "68%",
    line3_y: "85%",
  },
  {
    value: "legacy_17",
    line1_y: "50%",
    line2_y: "68%",
    line3_y: "85%",
  },
  {
    value: "legacy_18",
    line1_x: "40%",
    line1_y: "20%",
    line2_x: "40%",
    line2_y: "35%",
    line3_x: "40%",
    line3_y: "50%",
  },
  {
    value: "legacy_19",
    line1_x: "60%",
    line2_x: "60%",
    line3_x: "60%",
    fontColor: "#000",
  },
  {
    value: "legacy_20",
    line1_y: "50%",
    line2_y: "66%",
    line3_y: "80%",
  },
  {
    value: "legacy_21",
    line1_x: "60%",
    line2_x: "60%",
    line3_x: "60%",
    fontColor: "#000",
  },
  {
    value: "legacy_22",
    line1_y: "50%",
    line2_y: "66%",
    line3_y: "80%",
    fontColor: "#000",
  },
  {
    value: "legacy_23",
    fontColor: "#000",
  },
  {
    value: "legacy_24",
    line1_x: "60%",
    line1_y: "55%",
    line2_x: "60%",
    line2_y: "71%",
    line3_x: "60%",
    line3_y: "85%",
    fontColor: "#000",
  },
  {
    value: "legacy_25",
    line1_y: "20%",
    line2_y: "36%",
    fontColor: "#000",
  },
  {
    value: "legacy_26",
    line1_y: "30%",
    line2_y: "51%",
    fontColor: "#000",
  },
  {
    value: "legacy_27",
    line1_x: "40%",
    line2_x: "40%",
    line3_x: "40%",
    fontColor: "#000",
  },
  {
    value: "legacy_28",
    line1_y: "30%",
    line2_y: "48%",
    line3_y: "68%",
    fontColor: "#000",
  },
  {
    value: "legacy_29",
    line1_y: "30%",
    line2_y: "48%",
    line3_y: "68%",
    fontColor: "#000",
  },
  {
    value: "legacy_30",
    line1_y: "30%",
    line2_y: "48%",
    line3_y: "68%",
    fontColor: "#000",
  },
  {
    value: "legacy_31",
    line1_y: "30%",
    line2_y: "48%",
    line3_y: "68%",
    fontColor: "#000",
  },
]
legacyCardCovers.forEach(setDefaults)

/**
 * Convert a Blob to a base64-encoded data string
 * @param blob
 * @return {Promise<string>}
 */
export function blobToBase64(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onerror = reject
    reader.onload = () => {
      resolve(reader.result)
    }

    reader.readAsDataURL(blob)
  })
}

export function loadFont(targetNode, fontCssUrl) {
  const request = new XMLHttpRequest()
  request.open("get", fontCssUrl)
  request.responseType = "text"
  request.send()
  request.onloadend = () => {
    let fontCss = request.response
    const fontURLs = fontCss.match(/https?:\/\/[^ )]+/g)
    let loaded = 0
    fontURLs.forEach(url => {
      const requestData = new XMLHttpRequest()
      requestData.open("get", url)
      requestData.responseType = "blob"
      requestData.onloadend = () => {
        const reader = new FileReader()
        reader.onloadend = () => {
          fontCss = fontCss.replace(new RegExp(url), reader.result)
          loaded++
          if (loaded === fontURLs.length) {
            const svgElement = targetNode.querySelector("svg")
            // Remove all existing style elements
            const styleElements = svgElement.getElementsByTagName("style")
            if (styleElements) {
              for (const styleElement of styleElements) {
                if (styleElement.remove) {
                  styleElement.remove()
                }
              }
            }
            // Add in new style element for the selected font
            const styleEl = document.createElement("style")
            styleEl.appendChild(document.createTextNode(fontCss))
            svgElement.appendChild(styleEl)
          }
        }
        reader.readAsDataURL(requestData.response)
      }
      requestData.send()
    })
  }
}

export const uploadDeluxeImage = async data => {
  const fileId = uuidv4()
  const filename = `${fileId}-deluxe-front.png`
  await Storage.put(filename, data, {
    level: "protected",
    contentType: "image/png",
    acl: "public-read",
  })
  const signedUrl = await Storage.get(filename, {
    level: "protected",
  })
  const url = new URL(signedUrl)
  return `https://${url.host}${url.pathname}`
}

export const uploadDesignerAssets = async data => {
  const fileId = uuidv4()
  const fileExtension = data.name.split(".").pop()
  const filename = `${fileId}-designer-assets.${fileExtension}`
  await Storage.put(filename, data, {
    level: "protected",
    contentType: data.type,
    acl: "public-read",
  })
  const signedUrl = await Storage.get(filename, {
    level: "protected",
  })
  const url = new URL(signedUrl)
  return `https://${url.host}${url.pathname}`
}

export const getCardType = cardType => {
  if (!cardType) {
    return "STD"
  }

  const cardTypeUpper = cardType.toUpperCase()
  if (cardTypeUpper === "STANDARD") {
    return "STD"
  }

  return cardTypeUpper
}

export const isDesigner = order => {
  return getCardType(order.cards.cardType) === "DESIGNER"
}

export const isDeluxe = order => {
  return getCardType(order.cards.cardType) === "DELUXE"
}

export const toTitleCase = str => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

const getSubTotal = order => {
  return isDesigner(order)
    ? order.cards.cost
    : order.accessories.summary.reduce(
        (cost, item) => cost + item.cost,
        order.cards.cost -
          (order.payment.promoDetails && order.payment.promoDetails.type !== "free_shipping"
            ? order.payment.promoDetails.discount
            : 0),
      )
}

export const getTotal = async (order, profileAddress) => {
  const subTotalCost = getSubTotal(order)
  return (
    subTotalCost +
    (isDesigner(order) ? 0 : order.shipping.cost) +
    (await applyTaxRate(order, profileAddress, subTotalCost))
  )
}

export const getTotalCost = async (order, profileAddress) => {
  const totalCost = await getTotal(order, profileAddress)

  return totalCost / 100
}

export const getSubTotalCost = order => {
  const subTotalCost = getSubTotal(order)
  return subTotalCost / 100
}

export const getPromoPercentageValue = (order, percentage) => {
  const itemsCost = isDesigner(order)
    ? order.cards.cost
    : order.accessories.summary.reduce((cost, item) => cost + item.cost, order.cards.cost)

  return Math.ceil((percentage * itemsCost) / 100)
}

export const getFreeShippingValue = order => {
  return isDesigner(order) ? 0 : order.shipping.cost
}

export const getTaxValue = async (order, profileAddress) => {
  const subTotalCost = getSubTotal(order)
  const taxValue = await applyTaxRate(order, profileAddress, subTotalCost)

  return taxValue / 100
}

export const getReceiptValues = async (order, profileAddress) => {
  let subtotalValue = getSubTotal(order)
  let taxValue = await applyTaxRate(order, profileAddress, subtotalValue)
  let shippingValue =
    isDesigner(order) ||
    (order.payment.promoDetails &&
      order.payment.promoDetails.discount > 0 &&
      order.payment.promoDetails.type === "free_shipping")
      ? 0
      : order.shipping.cost
  let totalValue = subtotalValue + taxValue + shippingValue

  //Convert the return values
  subtotalValue = subtotalValue / 100
  taxValue = taxValue / 100
  totalValue = totalValue / 100
  shippingValue = shippingValue / 100

  return [subtotalValue, taxValue, totalValue, shippingValue]
}
