import taxRateTable from "../constants/taxRates.json"
import { isDesigner } from "./card"
import { API, graphqlOperation } from "aws-amplify"
import { getTaxRate } from "../graphql/queries"

const defaultTaxRate = 0

export const applyTaxRate = async (order, profileAddress, subTotal) => {
  let taxRate = defaultTaxRate
  let zipCode = order?.shipping?.shipToAddress?.shipZipCode
    ? order.shipping.shipToAddress.shipZipCode
    : null

  if (isDesigner(order)) {
    if (profileAddress && profileAddress.shipZipCode) {
      zipCode = profileAddress.shipZipCode
    } else if (order?.payment?.paymentDetails?.billingZipCode) {
      zipCode = order.payment.paymentDetails.billingZipCode
    }
  }

  if (zipCode) {
    if (zipCode.length > 5 && !zipCode.includes("-")) {
      zipCode = zipCode.slice(0, 5) + "-" + zipCode.slice(5)
    }

    await getTaxRateByZipCodeServiceNew(zipCode).then(taxRateByZipCodeService => {
      if (
        taxRateByZipCodeService &&
        "rate" in taxRateByZipCodeService &&
        taxRateByZipCodeService["rate"] >= 0
      ) {
        taxRate = taxRateByZipCodeService["rate"]
      } else {
        let taxRateByZipCodeJson = getTaxRateByZipCodeJson(zipCode.slice(0, 5))
        if (
          taxRateByZipCodeJson &&
          taxRateByZipCodeJson.length > 0 &&
          "tax_rate" in taxRateByZipCodeJson[0] &&
          taxRateByZipCodeJson[0]["tax_rate"] >= 0
        ) {
          taxRate = taxRateByZipCodeJson[0]["tax_rate"]
        }
      }
    })
  }
  return Math.ceil(taxRate * subTotal)
}

const getTaxRateByZipCodeJson = zipCode => {
  return taxRateTable.filter(item => item.zip === zipCode)
}

const getTaxRateByZipCodeServiceNew = async zipCode => {
  try {
    const result = await API.graphql(
      graphqlOperation(getTaxRate, { postal_code: zipCode.toString() }),
    )
    const {
      data: { getTaxRate: response },
    } = result
    return response
  } catch (e) {
    console.error("Rate error", e["errors"])
  }
}
