import { API, graphqlOperation } from "aws-amplify"
import { PHYSICAL_CARD_TERMS } from "../constants/terms"
import { getProfile } from "../graphql/queries"

export async function buildProfile() {
  const profile = JSON.parse(await API.graphql(graphqlOperation(getProfile)))
  if (profile.physicalCardTerms == null) {
    profile.physicalCardTerms = PHYSICAL_CARD_TERMS
  }
  return profile
}

export async function fetchProfileAddress(gatewayMid) {
  try {
    const defaultShipAddress = [
      {
        shipAddress1: "",
        shipAddress2: "",
        shipCity: "",
        shipState: "",
        shipZipCode: "",
        default: true,
        label: "Default Shipping Label",
      },
    ]
    const {
      data: { getProfile: profile },
    } = await API.graphql(graphqlOperation(getProfile, { PK: gatewayMid }))

    return profile && profile.shipAddress ? defaultAddress(profile.shipAddress) : defaultShipAddress
  } catch (e) {
    console.log("Error while fetching profile address ", e)
  }
}

const defaultAddress = shipAddress => {
  return shipAddress.filter(
    address =>
      address.default === null || address.default === undefined || address.default === true,
  )
}
