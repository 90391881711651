/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listLocations = /* GraphQL */ `
  query ListLocations {
    listLocations {
      locationID
      merchantId
      name
      enabled
      billingFlag
      bank {
        name
        address {
          addressId
          addressLine1
          addressLine2
          addressLine3
          city
          state
          zipCode
          countryCode
          __typename
        }
        brandLocation
        locationId
        __typename
      }
      address {
        addressId
        addressLine1
        addressLine2
        addressLine3
        city
        state
        zipCode
        countryCode
        __typename
      }
      contactInfo {
        primary
        contactName
        phoneNumber
        __typename
      }
      programType
      __typename
    }
  }
`
export const getMerchant = /* GraphQL */ `
  query GetMerchant {
    getMerchant {
      baseInfo {
        billingPlatform
        channel
        crossConsortiumList
        currencyCode
        enabled
        entitlementAttributes
        leadLocation
        mccCode
        merchantName
        merchantType
        securityPlan
        __typename
      }
      locations {
        locationID
        merchantId
        name
        enabled
        billingFlag
        bank {
          name
          brandLocation
          locationId
          __typename
        }
        address {
          addressId
          addressLine1
          addressLine2
          addressLine3
          city
          state
          zipCode
          countryCode
          __typename
        }
        contactInfo {
          primary
          contactName
          phoneNumber
          __typename
        }
        programType
        __typename
      }
      __typename
    }
  }
`
export const getConsortium = /* GraphQL */ `
  query GetConsortium {
    getConsortium {
      consortiumCode
      baseInfo {
        billingPlatform
        channel
        crossConsortiumList
        currencyCode
        enabled
        entitlementAttributes
        leadLocation
        mccCode
        merchantName
        merchantType
        securityPlan
        __typename
      }
      locations {
        locationID
        merchantId
        name
        enabled
        billingFlag
        bank {
          name
          brandLocation
          locationId
          __typename
        }
        address {
          addressId
          addressLine1
          addressLine2
          addressLine3
          city
          state
          zipCode
          countryCode
          __typename
        }
        contactInfo {
          primary
          contactName
          phoneNumber
          __typename
        }
        programType
        __typename
      }
      promos {
        promocode
        promoDescription
        created
        attributes
        maxActivateAmount
        maxBalance
        maxReloadAmount
        maxSaleAmount
        minActivateAmount
        minBalance
        minReloadAmount
        minSaleAmount
        binDesc
        currencyCode
        expiration
        __typename
      }
      cardInventory {
        bin
        cardLength
        cards
        progress
        promocode
        replenish
        requestDate
        type
        __typename
      }
      merchants {
        description
        discount
        enable
        merchantAttributes
        merchantNo
        startOfBusiness
        transactionSet
        __typename
      }
      aggregators {
        aggregatorMIDs
        aggregatorName
        aggregatorTransactionSet
        __typename
      }
      reports {
        reportCutoffTime
        reportFrequency
        reportGMTCutoffTime
        reportId
        reportMWYMailbox
        reportName
        reportTimezone
        reportTransmissionMethod
        reportVersion
        __typename
      }
      __typename
    }
  }
`
export const listAllLocations = /* GraphQL */ `
  query ListAllLocations($page: Int, $size: Int) {
    listAllLocations(page: $page, size: $size) {
      locationID
      merchantId
      name
      enabled
      billingFlag
      bank {
        name
        address {
          addressId
          addressLine1
          addressLine2
          addressLine3
          city
          state
          zipCode
          countryCode
          __typename
        }
        brandLocation
        locationId
        __typename
      }
      address {
        addressId
        addressLine1
        addressLine2
        addressLine3
        city
        state
        zipCode
        countryCode
        __typename
      }
      contactInfo {
        primary
        contactName
        phoneNumber
        __typename
      }
      programType
      __typename
    }
  }
`
export const listPromos = /* GraphQL */ `
  query ListPromos($page: Int, $size: Int) {
    listPromos(page: $page, size: $size) {
      promocode
      promoDescription
      created
      attributes
      maxActivateAmount
      maxBalance
      maxReloadAmount
      maxSaleAmount
      minActivateAmount
      minBalance
      minReloadAmount
      minSaleAmount
      binDesc
      currencyCode
      expiration
      __typename
    }
  }
`
export const listCardInventory = /* GraphQL */ `
  query ListCardInventory($page: Int, $size: Int) {
    listCardInventory(page: $page, size: $size) {
      bin
      cardLength
      cards
      progress
      promocode
      replenish
      requestDate
      type
      __typename
    }
  }
`
export const listMerchants = /* GraphQL */ `
  query ListMerchants($page: Int, $size: Int) {
    listMerchants(page: $page, size: $size) {
      description
      discount
      enable
      merchantAttributes
      merchantNo
      startOfBusiness
      transactionSet
      __typename
    }
  }
`
export const listAggregators = /* GraphQL */ `
  query ListAggregators($page: Int, $size: Int) {
    listAggregators(page: $page, size: $size) {
      aggregatorMIDs
      aggregatorName
      aggregatorTransactionSet
      __typename
    }
  }
`
export const listProducts = /* GraphQL */ `
  query ListProducts($page: Int, $size: Int) {
    listProducts(page: $page, size: $size) {
      productId
      enabled

      ... on FINCENProduct {
        fincenEnabled
        fincendailyLoadTrans
        fincenloadLimit
        fincenloadMonPeriod
      }
      ... on NRTMProduct {
        nrtmEnabled
        balanceChangeAlert
        taxSourcePlatform
      }
      ... on CardTreeProduct {
        cardTreeEnabled
        cardBalanceChangeAlert
        cardStatusChangeAlert
      }
      ... on PerkaProduct {
        perkaEnabled
      }
      ... on LLMProduct {
        llmEnabled
        llmLoadTrans
        llmloadLimit
        llmloadMonPeriod
      }
      ... on TaxProduct {
        taxEnabled
        taxInfoFlag
        taxSourcePlatform
      }
      ... on VCARProduct {
        vcarEnabled
        vcarEmail1
        vcarEmail2
        vcarReplenishmentQuantity
        vcarThreshold
        vcarThresholdType
      }
      ... on FraudProduct {
        fraudEnabled
        fraudBIConsecutiveThreshold
        fraudBIHighThreshold
        fraudBILowThreshold
        fraudCKSThreshold
        fraudCardStatus
        fraudEANTheshold
        fraudMaxCount
        fraudSources
        fraudTransactions
        restrictTxnListEnabled
        restrictTxnListSource30
        restrictTxnListSource31
        restrictTxnListWatch
      }
      ... on DualBalanceProduct {
        merchantFundedEnabled
        merchantFundedAltMID
        merchantFundedMID
      }
      ... on LTOProduct {
        ltoEnabled
        ltoAltMID
        ltoMID
      }
      ... on SettlementProduct {
        settlementEnabled
      }
    }
  }
`
export const listReports = /* GraphQL */ `
  query ListReports($page: Int, $size: Int) {
    listReports(page: $page, size: $size) {
      reportCutoffTime
      reportFrequency
      reportGMTCutoffTime
      reportId
      reportMWYMailbox
      reportName
      reportTimezone
      reportTransmissionMethod
      reportVersion
      __typename
    }
  }
`
export const getVLReports = /* GraphQL */ `
  query GetVLReports($input: VLReportInput!) {
    getVLReports(input: $input) {
      consortiumCode
      gatewayMID
      reportDate
      reportFrequency
      reportName
      reportType
      reportUrl
      __typename
    }
  }
`
export const getGyftTransactions = /* GraphQL */ `
  query GetGyftTransactions($input: GyftTransactionsInput!) {
    getGyftTransactions(input: $input) {
      limit
      count
      filters {
        startDate
        endDate
        orderNumber
        storeLocation
        cardNumber
        __typename
      }
      transactions {
        cardNumber
        cardBalance
        transactionDate
        location
        transactionValue
        transactionType
        __typename
      }
      __typename
    }
  }
`
export const downloadGyftTransactions = /* GraphQL */ `
  query DownloadGyftTransactions($input: DownloadGyftTransactionsInput!) {
    downloadGyftTransactions(input: $input) {
      file
      __typename
    }
  }
`
export const getTaxRate = /* GraphQL */ `
  query GetTaxRate($postal_code: String) {
    getTaxRate(postal_code: $postal_code) {
      rate
      __typename
    }
  }
`
export const getOrder = /* GraphQL */ `
  query GetOrder($PK: String!, $createdAt: AWSDateTime!) {
    getOrder(PK: $PK, createdAt: $createdAt) {
      PK
      createdAt
      updatedAt
      cognitoSub
      username
      gatewayMID
      closedLoopMID
      authProvider
      orderId
      gcSource
      cardOrderId
      email
      itemType
      requestedShipMethod
      shipToAddress {
        label
        default
        shipAddress1
        shipAddress2
        shipCity
        shipState
        shipZipCode
        __typename
      }
      items {
        orderCategory
        isCardCarrier
        quantity
        cost
        description
        type
        artWorkFileName
        artWorkText
        artWorkBackText
        contactDetails {
          businessName
          email
          phoneNumber
          __typename
        }
        designerAssetsUrl
        notes
        __typename
      }
      status
      pending
      state
      vlOrderId
      payment {
        paymentStatus
        paymentMethod
        paymentId
        subtotalAmount
        totalAmount
        taxAmount
        shippingAmount
        paymentDetails {
          cardholder
          cardType
          lastFour
          billingAddress1
          billingAddress2
          billingCity
          billingState
          billingZipCode
          __typename
        }
        promoDetails {
          discount
          promoCode
          text
          type
          value
          params
          __typename
        }
        __typename
      }
      trackingNumber
      couponCode
      couponValidationParams
      itemsJson
      paymentJson
      shipToAddressJson
      itemsCount
      merchantName
      designOrderId
      originalOrderId
      __typename
    }
  }
`
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $PK: String
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOrders(
      PK: $PK
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        PK
        createdAt
        updatedAt
        cognitoSub
        username
        gatewayMID
        closedLoopMID
        authProvider
        orderId
        gcSource
        cardOrderId
        email
        itemType
        requestedShipMethod
        shipToAddress {
          label
          default
          shipAddress1
          shipAddress2
          shipCity
          shipState
          shipZipCode
          __typename
        }
        items {
          orderCategory
          isCardCarrier
          quantity
          cost
          description
          type
          artWorkFileName
          artWorkText
          artWorkBackText
          designerAssetsUrl
          notes
          __typename
        }
        status
        pending
        state
        vlOrderId
        payment {
          paymentStatus
          paymentMethod
          paymentId
          subtotalAmount
          totalAmount
          taxAmount
          shippingAmount
          __typename
        }
        trackingNumber
        couponCode
        couponValidationParams
        itemsJson
        paymentJson
        shipToAddressJson
        itemsCount
        merchantName
        designOrderId
        originalOrderId
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getProfile = /* GraphQL */ `
  query GetProfile($PK: String!) {
    getProfile(PK: $PK) {
      PK
      physicalCardTerms
      shipAddress {
        label
        default
        shipAddress1
        shipAddress2
        shipCity
        shipState
        shipZipCode
        __typename
      }
      createdAt
      updatedAt
      shipAddressJson
      __typename
    }
  }
`
export const listProfiles = /* GraphQL */ `
  query ListProfiles(
    $PK: String
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProfiles(
      PK: $PK
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        PK
        physicalCardTerms
        shipAddress {
          label
          default
          shipAddress1
          shipAddress2
          shipCity
          shipState
          shipZipCode
          __typename
        }
        createdAt
        updatedAt
        shipAddressJson
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getGiftConversionJob = /* GraphQL */ `
  query GetGiftConversionJob($jobId: String!) {
    getGiftConversionJob(jobId: $jobId) {
      jobId
      fileName
      merchantId
      createdAt
      updatedAt
      uploadedBy
      status
      metrics {
        rows
        errors
        __typename
      }
      contactEmail
      audit {
        jobId
        fileName
        merchantId
        createdAt
        updatedAt
        uploadedBy
        status
        metrics {
          rows
          errors
          __typename
        }
        contactEmail
        errorFileLocation
        fileLocation
        version
        __typename
      }
      errorFileLocation
      fileLocation
      version
      __typename
    }
  }
`
export const listGiftConversionJobs = /* GraphQL */ `
  query ListGiftConversionJobs(
    $jobId: String
    $filter: ModelGiftConversionJobFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGiftConversionJobs(
      jobId: $jobId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        jobId
        fileName
        merchantId
        createdAt
        updatedAt
        uploadedBy
        status
        metrics {
          rows
          errors
          __typename
        }
        contactEmail
        audit {
          jobId
          fileName
          merchantId
          createdAt
          updatedAt
          uploadedBy
          status
          contactEmail
          errorFileLocation
          fileLocation
          version
          __typename
        }
        errorFileLocation
        fileLocation
        version
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getCoupon = /* GraphQL */ `
  query GetCoupon($PK: String!) {
    getCoupon(PK: $PK) {
      PK
      couponCode
      couponStatus
      couponType
      couponDiscount
      beginsAt
      expiresAt
      createdAt
      multiUse
      authProviders
      closedLoopMIDs
      gatewayMIDs
      orderTypes
      updatedAt
      __typename
    }
  }
`
export const listCoupons = /* GraphQL */ `
  query ListCoupons(
    $PK: String
    $filter: ModelCouponFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCoupons(
      PK: $PK
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        PK
        couponCode
        couponStatus
        couponType
        couponDiscount
        beginsAt
        expiresAt
        createdAt
        multiUse
        authProviders
        closedLoopMIDs
        gatewayMIDs
        orderTypes
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getShippingConfig = /* GraphQL */ `
  query GetShippingConfig($pk: String!, $sk: String!) {
    getShippingConfig(pk: $pk, sk: $sk) {
      pk
      sk
      authProvider
      cardQuantity
      courier
      currencyCode
      printerProvider
      shipCost
      shipMethod
      shipMethodDescription
      shipTime
      createdAt
      updatedAt
      __typename
    }
  }
`
export const listShippingConfigs = /* GraphQL */ `
  query ListShippingConfigs(
    $pk: String
    $sk: ModelStringKeyConditionInput
    $filter: ModelShippingConfigFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listShippingConfigs(
      pk: $pk
      sk: $sk
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        sk
        authProvider
        cardQuantity
        courier
        currencyCode
        printerProvider
        shipCost
        shipMethod
        shipMethodDescription
        shipTime
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const pendingOrders = /* GraphQL */ `
  query PendingOrders(
    $pending: Int
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pendingOrders(
      pending: $pending
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        PK
        createdAt
        updatedAt
        cognitoSub
        username
        gatewayMID
        closedLoopMID
        authProvider
        orderId
        gcSource
        cardOrderId
        email
        itemType
        requestedShipMethod
        shipToAddress {
          label
          default
          shipAddress1
          shipAddress2
          shipCity
          shipState
          shipZipCode
          __typename
        }
        items {
          orderCategory
          isCardCarrier
          quantity
          cost
          description
          type
          artWorkFileName
          artWorkText
          artWorkBackText
          designerAssetsUrl
          notes
          __typename
        }
        status
        pending
        state
        vlOrderId
        payment {
          paymentStatus
          paymentMethod
          paymentId
          subtotalAmount
          totalAmount
          taxAmount
          shippingAmount
          __typename
        }
        trackingNumber
        couponCode
        couponValidationParams
        itemsJson
        paymentJson
        shipToAddressJson
        itemsCount
        merchantName
        designOrderId
        originalOrderId
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const orderByOrderId = /* GraphQL */ `
  query OrderByOrderId(
    $orderId: String
    $gatewayMID: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orderByOrderId(
      orderId: $orderId
      gatewayMID: $gatewayMID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        PK
        createdAt
        updatedAt
        cognitoSub
        username
        gatewayMID
        closedLoopMID
        authProvider
        orderId
        gcSource
        cardOrderId
        email
        itemType
        requestedShipMethod
        shipToAddress {
          label
          default
          shipAddress1
          shipAddress2
          shipCity
          shipState
          shipZipCode
          __typename
        }
        items {
          orderCategory
          isCardCarrier
          quantity
          cost
          description
          type
          artWorkFileName
          artWorkText
          artWorkBackText
          designerAssetsUrl
          notes
          __typename
        }
        status
        pending
        state
        vlOrderId
        payment {
          paymentStatus
          paymentMethod
          paymentId
          subtotalAmount
          totalAmount
          taxAmount
          shippingAmount
          __typename
        }
        trackingNumber
        couponCode
        couponValidationParams
        itemsJson
        paymentJson
        shipToAddressJson
        itemsCount
        merchantName
        designOrderId
        originalOrderId
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const orderByCouponAndValidationParams = /* GraphQL */ `
  query OrderByCouponAndValidationParams(
    $couponCode: String
    $couponValidationParams: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orderByCouponAndValidationParams(
      couponCode: $couponCode
      couponValidationParams: $couponValidationParams
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        PK
        createdAt
        updatedAt
        cognitoSub
        username
        gatewayMID
        closedLoopMID
        authProvider
        orderId
        gcSource
        cardOrderId
        email
        itemType
        requestedShipMethod
        shipToAddress {
          label
          default
          shipAddress1
          shipAddress2
          shipCity
          shipState
          shipZipCode
          __typename
        }
        items {
          orderCategory
          isCardCarrier
          quantity
          cost
          description
          type
          artWorkFileName
          artWorkText
          artWorkBackText
          designerAssetsUrl
          notes
          __typename
        }
        status
        pending
        state
        vlOrderId
        payment {
          paymentStatus
          paymentMethod
          paymentId
          subtotalAmount
          totalAmount
          taxAmount
          shippingAmount
          __typename
        }
        trackingNumber
        couponCode
        couponValidationParams
        itemsJson
        paymentJson
        shipToAddressJson
        itemsCount
        merchantName
        designOrderId
        originalOrderId
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const merchantConversion = /* GraphQL */ `
  query MerchantConversion(
    $merchantId: String
    $sortDirection: ModelSortDirection
    $filter: ModelGiftConversionJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    merchantConversion(
      merchantId: $merchantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        jobId
        fileName
        merchantId
        createdAt
        updatedAt
        uploadedBy
        status
        metrics {
          rows
          errors
          __typename
        }
        contactEmail
        audit {
          jobId
          fileName
          merchantId
          createdAt
          updatedAt
          uploadedBy
          status
          contactEmail
          errorFileLocation
          fileLocation
          version
          __typename
        }
        errorFileLocation
        fileLocation
        version
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const conversionStatus = /* GraphQL */ `
  query ConversionStatus(
    $status: ConversionStatus
    $sortDirection: ModelSortDirection
    $filter: ModelGiftConversionJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    conversionStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        jobId
        fileName
        merchantId
        createdAt
        updatedAt
        uploadedBy
        status
        metrics {
          rows
          errors
          __typename
        }
        contactEmail
        audit {
          jobId
          fileName
          merchantId
          createdAt
          updatedAt
          uploadedBy
          status
          contactEmail
          errorFileLocation
          fileLocation
          version
          __typename
        }
        errorFileLocation
        fileLocation
        version
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const shipMethods = /* GraphQL */ `
  query ShipMethods(
    $pk: String
    $shipMethod: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelShippingConfigFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shipMethods(
      pk: $pk
      shipMethod: $shipMethod
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pk
        sk
        authProvider
        cardQuantity
        courier
        currencyCode
        printerProvider
        shipCost
        shipMethod
        shipMethodDescription
        shipTime
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`
